import OurProducts from "../Homepage/products";
import WeAre from "../Homepage/weare";
import Footer from "../Footer/footer";
import Landingpage from "../Homepage/landingpage";
function Main() {
  return (
    <>

      <Landingpage />
      <WeAre />
      <OurProducts />
      <Footer />
      
    </>
  );
}

export default Main;
